<template>
    <div class="timing-hall-restaurant">
        <router-link class="timing-hall-restaurant__link timing-hall-restaurant__link_back link link_red"
            ref="backLink"
            v-if="userRestaurants.ids.length > 1"
            :to="{ name: 'TimingHallTotal' }"
        >
            Вернуться к общему отчету "Время. Зал"
        </router-link>
        <timing-hall-settings class="timing-hall-restaurant__settings"
            :showModesSelect="false"
        />
        <spinner v-if="status === 'loading'" class="timing-hall-restaurant__spinner" />
        <error-message v-else-if="status === 'error'" class="timing-hall-restaurant__error-message">
            Не удалось загрузить данные отчета "Время. Зал".
        </error-message>
        <!--
        <section v-else-if="status === 'success'" class="timing-hall-restaurant__data">
            <h2 class="timing-hall-restaurant__data-heading sr-only">Данные отчета "Время. Зал"</h2>
            <timing-hall-base-table v-if="modeId === 'base'"
                :columns="columns"
                :totalRow="baseReportTotal"
                :rows="reportRestaurants"
            />
            <template v-else-if="modeId === 'compareOtherPeriod'">
                <p class="timing-hall-restaurant__error" v-if="!compareTimingHall">Выберите период сравнения.</p>
                <template v-else>
                    <timing-hall-compare-table
                        :columns="columns"
                        :reportRow="compareReportTotal"
                        :compareRow="compareTotal"
                    />
                    <timing-hall-compare-table
                        v-for="restaurant in reportRestaurants"
                        :key="restaurant.id"
                        :columns="columns"
                        :reportRow="restaurant"
                        :compareRow="findCompareRestaurant(compareRestaurants, restaurant)"
                    />
                </template>
            </template>
        </section>
         -->
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import TimingHallSettings from "./common/TimingHallSettings";
    import TimingHallBaseTable from "./common/TimingHallBaseTable";
    import TimingHallCompareTable from "./common/TimingHallCompareTable";

    let totalTitle = "Таминги заказов всех ресторанов";

    export default {
        name: "TimingHallRestaurant",
        components: {
            TimingHallSettings,
            TimingHallBaseTable,
            TimingHallCompareTable,
        },
        props: {
            id: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                status: "loading",
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                reportDaterange: state => state.report.daterange,
                reportRestaurants: state => state.report.restaurants,
                compareDaterange: state => state.timingHall.compareDaterange,
                modeId: state => state.timingHall.modeId,
                sorting: state => state.timingHall.sorting,
                groupKitchenDepartments: state => state.timingHall.groupKitchenDepartments,
                data: state => state.timingHall.data.orders,
            }),
            ...mapGetters([
                "userRestaurants",
                "reportRestaurantsKey",
                "reportKey",
            ]),
            requiredTimingHallOrders() {
                let requiredTimingHallOrders = [this.data.report];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    requiredTimingHallOrders.push(this.data.compare);
                }

                return requiredTimingHallOrders;
            },

        },
        methods: {
            ...mapMutations([
                "setTimingHallSorting",
                "setTitle",
                "setTimingHallParameter",
            ]),
            ...mapActions([
                "requestHallTimings"
            ]),
            findCompareRestaurant(compareRestaurants, reportRestaurant) {
                let compareRestaurant =  compareRestaurants?.find(({ id }) =>id === reportRestaurant.id);

                if (typeof compareRestaurant === "undefined") {
                    return {
                        title: reportRestaurant.title
                    }
                }

                return compareRestaurant;
            },
            showReport() {
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
            loadHallTimings() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`])
                );

                this.requestHallTimings({
                    dateranges: daterangesWithoutData,
                    restaurants: this.reportRestaurants,
                    restaurantsKey: this.reportRestaurantsKey,
                }).then(this.showReport, this.showError);
            },
            initTimingHallRestaurant() {
                if (this.id !== "total" && !this.userRestaurants?.ids?.includes(this.id)) {
                    this.$router.push({
                        name: "Error401",
                        params: {
                            requestedResource: `Ресторан ${this.id}`
                        }
                    });
                } else {
                    if (this.id === "total" && this.title !== totalTitle) {
                        this.setTitle(totalTitle);
                    } else {
                        let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                        if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                            this.setTitle(`${this.title} "${restaurantTitle}"`);
                        }
                    }

                    if (this.modeId === "compareOtherPeriod") {
                        this.setTimingHallParameter({
                            parameter: "modeId",
                            value: "base"
                        });
                    }

                    if (this.id === "total" && this.reportRestaurants.length === 0) {
                        this.status = "no-restaurants";
                    } else if (this.requiredTimingHallOrders?.every(Boolean)) {
                        this.showReport();
                    } else {
                        // this.loadHallTimings();
                    }
                }
            }
        },
        created() {
            this.initTimingHallRestaurant();
        },
        watch: {
            id() {
                this.initTimingHallRestaurant();
            },
            requiredTimingHallOrders(requiredTimingHallOrders) {
                if (this.reportRestaurants?.length === 0) {
                    this.status = "no-restaurants";
                } else if (!requiredTimingHallOrders?.every(Boolean)) {
                    // this.loadHallTimings();
                } else {
                    this.showReport();
                }
            }
        },
    }
</script>

<style lang="scss">
    .timing-hall-restaurant__link {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }
    .timing-hall-restaurant__data {
        display: grid;
        grid-gap: 16px;
    }
</style>
